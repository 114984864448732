import React from 'react';

const successIcon = require('./icons/success.png');
const errorIcon = require('./icons/error.png');
const warningIcon = require('./icons/warning.png');

enum ResultStatus {
    Idle = "",
    Success = "Asistencia confirmada!",
    Warning = "Ya recibimos su confirmación anteriormente. Gracias!",
    Failure = "Ocurrió un error, por favor intente nuevamente"
}

interface ResultModalParams {
    result: ResultStatus
    showModal: boolean
    onClose: Function
}

function getIcon(result: ResultStatus) {
    switch(result) {
        case ResultStatus.Idle:
            return warningIcon
        case ResultStatus.Success:
            return successIcon
        case ResultStatus.Warning:
            return warningIcon
        case ResultStatus.Failure:
            return errorIcon
    }
}

function ResultModal(params: ResultModalParams) {

    const showModal = params.showModal

    return (
        <div className={ `modal ${ showModal && 'is-active' }` }>
            <div className="modal-background"></div>
            <div className="modal-content is-family-primary has-text-centered">
                <div className='box m-6'>
                    <figure className='image is-64x64 is-inline-block'>
                        <img src={ getIcon(params.result) } alt=''></img>
                    </figure>
                    <p className="subtitle is-5">{ params.result }</p>
                    {
                        params.result === ResultStatus.Success ? 
                            (<button className="button is-primary is-fullwidth" onClick={(event) => { params.onClose() }}>OK</button>) : 
                            (<button className="button is-fullwidth" onClick={(event) => { params.onClose() }}>Regresar</button>)
                    }
                    
                </div>
            </div>
        </div>
    );
}

export { ResultModal, ResultStatus };