import axios from 'axios';

interface RSVPFormFields {
    fullName: string,
    dni: string,
    msg: string,
    isGoing: string
}

const baseUrl = "https://sheet.best/api/sheets/"

function submit(form: RSVPFormFields) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${baseUrl}${ process.env.REACT_APP_SHEET_BEST_API_ID }`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(form),
    }).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error)
    });
  })
};

function recordExists(form: RSVPFormFields) {
  return new Promise((resolve, reject) => {
    axios<RSVPFormFields[]>({
      method: 'get',
      url: `${baseUrl}${ process.env.REACT_APP_SHEET_BEST_API_ID }/query?dni=__eq(${ form.dni })`
    }).then(response => {
      resolve(response.data.length > 0)
    }).catch(error => {
      reject(error)
    });
  })
}

export { submit, recordExists };