import React from 'react';
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { ResultModal, ResultStatus } from './modal/ResultModal';
import LoadingModal from './LoadingModal';

import { submit, recordExists } from './rsvpRepository';
import validator from 'validator';

interface FormData {
    fullName: string,
    dni: string,
    msg: string,
    isGoing: string
}

function RSVPForm() {

    const [showLoading, setShowLoading] = useState(false);
    const [submitResult, setSubmitResult] = useState(ResultStatus.Idle);
 
    const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
        setShowLoading(true)
        recordExists({
            fullName: data.fullName,
            dni: data.dni,
            msg: data.msg,
            isGoing: data.isGoing
        }).then((exists) => {
            if (exists) {
                setShowLoading(false);
                setSubmitResult(ResultStatus.Warning);
                return
            }
            submit({
                fullName: data.fullName,
                dni: data.dni,
                msg: data.msg,
                isGoing: data.isGoing
            }).then((result) => {
                setShowLoading(false);
                setSubmitResult(ResultStatus.Success)
            }).catch((error) => {
                setShowLoading(false);
                setSubmitResult(ResultStatus.Failure);
            })
        }).catch((error) => {
            setShowLoading(false);
            setSubmitResult(ResultStatus.Failure);
        })
    }

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>()
    
    return(
        <>
        <section className='hero has-secondary-background is-fullheight'>
            <div className='hero-body has-text-centered'>
                <div className="container is-family-primary">
                    <div className='container'>
                        <h2 className="title is-2 has-text-weight-semibold">RSVP</h2>
                        <h2 className='title is-4 has-text-weight-light'>Confírmanos tu asistencia llenando tus datos en este pequeño formulario.</h2>
                    </div>
                    <form className='content' onSubmit={ handleSubmit(onSubmit) }>
                    <div className='section'>
                        <div className="field">
                            <label className="label"></label>
                            <div className="control">
                                <input 
                                    { 
                                        ...register("fullName", { 
                                            required: 'Campo requerido, por favor ingrese sus Nombres y Apellidos',
                                            validate: (value) => {
                                                return (validator.isAlpha(value,'es-ES', { ignore: ' ' })) || 'Por favor ingrese solo letras'
                                            }
                                        }) 
                                    }
                                    name='fullName' 
                                    className={`input is-medium ${ errors.fullName?.message && 'is-danger'}`}
                                    type="text" 
                                    placeholder="Nombres y Apellidos" 
                                />
                                { errors.fullName?.message && <p className="help is-danger"><b>{ `${ errors.fullName?.message }` }</b></p> }
                            </div>
                        </div>
                        <div className="field">
                            <label className="label"></label>
                            <div className="control">
                                <input 
                                    { 
                                        ...register("dni", { 
                                        required: 'Campo requerido, por favor ingrese su DNI',
                                        validate: (value) => {
                                                return (validator.isDecimal(value) && validator.isLength(value, { min: 8, max: 8 })) || 'DNI inválido, por favor ingrese un DNI correcto'
                                            }
                                        }) 
                                    }
                                    name='dni' 
                                    className={`input is-medium ${ errors.dni?.message && 'is-danger'}`}
                                    type='text'
                                    placeholder="DNI" 
                                    maxLength={ 8 }
                                />
                                { errors.dni?.message && <p className="help is-danger"><b>{ `${ errors.dni?.message }` }</b></p> }
                            </div>
                        </div>
                        <div className="field">
                            <label className="label"></label>
                                <div className="control">
                                    <textarea 
                                        { ...register("msg") }
                                        name='msg' 
                                        className="textarea is-medium" 
                                        placeholder="Mensaje para los novios (opcional)"  
                                    />
                                </div>
                        </div>
                        <div className='section'>
                            <div className="field is-grouped is-grouped-centered">
                                <div className='radios'>
                                    <div className='level'>
                                        <div className='level-item'>
                                        <label className="label title is-5">Asistirás?</label>
                                        </div>
                                        <div className='level-item'>
                                        <label className="radio is-medium">
                                        <input { 
                                            ...register("isGoing", 
                                            { required: 'Por favor indique su asistencia' }
                                            ) 
                                        } 
                                        type="radio" 
                                        name='isGoing' 
                                        value='yes'/>
                                        <b>SI</b>
                                    </label>
                                        </div>
                                        <div className='level-item'>
                                        <label className="radio is-medium">
                                        <input { 
                                            ...register("isGoing", 
                                            { required: 'Por favor indique su asistencia' }
                                            ) 
                                        } 
                                        type="radio" 
                                        name='isGoing' 
                                        value='no'/>
                                        <b>NO</b>
                                    </label>
                                        </div>
                                        <div className='level-item'>
                                        <label className="radio is-medium">
                                        <input { 
                                            ...register("isGoing", 
                                            { required: 'Por favor indique su asistencia' }
                                            ) 
                                        } 
                                        type="radio" 
                                        name='isGoing' 
                                        value='maybe'/>
                                        <b>TAL VEZ</b>
                                    </label>
                                        </div>
                                    </div>
                                    
                                </div>
                                { errors.isGoing?.message && <p className="help is-danger"><b>{ `${ errors.isGoing?.message }` }</b></p> }
                            </div>        
                        </div>
                        <span className='container'>
                            <p className='title is-6 has-text-weight-medium'>* Puedes confirmar tu asistencia hasta el 5 de Octubre del 2024.<br/>* La presente invitación es personal e intransferible.</p>
                            <span className="field is-grouped is-grouped-centered">
                                <div className="control">
                                    <button type='submit' className="button is-primary">Enviar Asistencia</button>
                                </div>
                            </span>
                        </span>
                        <LoadingModal 
                            showLoading= { showLoading }
                            titleText='Confirmando Su Asistencia'
                            bodyText='Por favor espere un momento'
                        />
                    </div>
                    </form>
                </div>
            </div>
        </section> 
        <ResultModal
            result= { submitResult }
            showModal= { submitResult !== ResultStatus.Idle }
            onClose={ () => { 
                setSubmitResult(ResultStatus.Idle)
                reset()
            }}
        />
    </>
    );
}

export default RSVPForm;