import React from 'react';

interface LoadingModalParams {
    titleText: string
    bodyText: string
    showLoading: boolean
}

function LoadingModal(params: LoadingModalParams) {
    return (
        <div className={ `modal ${ params.showLoading && 'is-active' }` }>
            <div className="modal-background"></div>
            <div className="modal-content is-family-primary">
                <div className='box m-6 has-text-centered'>
                    <p className="subtitle is-5">{ params.titleText }</p>
                    <p className="subtitle is-6">{ params.bodyText }</p>
                    <br/>
                        <progress className="progress is-small" max="100">60%</progress>
                </div>      
            </div>
        </div>
    );
}

export default LoadingModal;